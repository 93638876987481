import * as React from "react";
import Layout from "@components/layout";
import SEO from "@components/seo";
import JobPostingComponent from "@components/JobPosting";

const JobPostingPage = () => (
  <Layout>
    <SEO title="Work With Us" />
    <JobPostingComponent />
  </Layout>
);

export default JobPostingPage;
